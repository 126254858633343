<template>
  <div class="">
    <div class="tw-text-center tw-mb-5">
      <h1 class="tw-text-3xl sm:tw-text-4xl tw-font-bold sm:tw-block tw-mb-1">
        Kontak Admin
      </h1>
      <p class="tw-text-lg tw-text-gray-400">Nomor WhatsApp dan Email</p>
    </div>
    <FormKit
      type="tel"
      name="phone_number"
      label="Nomor Whatsapp Admin"
      placeholder="Masukkan nomor admin terhubung ke WA"
      validation="required|number|length:10,13"
      validation-visibility="dirty"
      :classes="defaultFormikClass"
    />

    <FormKit
      type="email"
      name="email"
      label="Email"
      placeholder="example@email.com"
      validation="required|email"
      validation-visibility="dirty"
      :classes="defaultFormikClass"
      help="Masukkan email yang aktif"
    />
    <div class="mt-3 form-group tw-w-full">
      <VueRecaptcha
        class="recaptcha"
        ref="recaptcha"
        :sitekey="sitekey"
        :loadRecaptchaScript="true"
        @verify="onVerifyCapthca"
        @expired="onExpiredCapthca"
      >
      </VueRecaptcha>
    </div>

    <div class="tw-mt-5">
      <div class="mb-0 field-checkbox tw-text-sm">
        <Checkbox id="checkOption1" :binary="true" v-model="isAgree" />
        <label for="checkOption1">
          Saya telah membaca dan menyetujui
          <span class="tw-font-bold">
            <a
              href="https://salonial.com/syarat-dan-ketentuan/"
              target="_blank"
            >
              syarat dan ketentuan
            </a>
          </span>
          serta memahami
          <span class="tw-font-bold">
            <a href="https://salonial.com/kebijakan-privasi/" target="_blank">
              kebijakan privasi
            </a>
          </span>
          yang berlaku.
        </label>
      </div>
    </div>
    <div class="tw-gap-3 tw-flex tw-items-center tw-justify-between">
      <Button
        label="Sebelumnya"
        icon="pi pi-arrow-left"
        outlined
        class="px-6 tw-mt-5 tw-bg-white tw-text-blue-600"
        @click="$emit('prev-page', { pageIndex: 2 })"
      />
      <Button
        label="Selesai"
        class="px-6 p-button-info tw-mt-5"
        @click="$emit('complete', { formData: { captcha_resp } })"
      />
    </div>
  </div>
</template>
<script setup>
import { defaultFormikClass } from "@/constants/formik";
import { ref } from "vue";
import { VueRecaptcha } from "vue-recaptcha";

const sitekey = ref(process.env.VUE_APP_RECAPTCHA_KEY);

const recaptcha = ref(null);

const captcha_resp = ref("");

const isAgree = ref(false);

const onVerifyCapthca = (recaptchaToken) => {
  captcha_resp.value = recaptchaToken;
};

const onExpiredCapthca = () => {
  captcha_resp.value = "";
  this.$refs.recaptcha.reset();
};
</script>
<style lang=""></style>
